import { kgToLbs, lbsToKg, cmToFtIn, ftInToCm } from "../dataStore"

export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  onInit(data) {
    this.formValues.age = data?.formValues?.age || this.store.values.age
    this.formValues.firstName = data?.formValues?.firstName || this.store.values.firstName

    if (this.store.isMetric) {
      this.formValues.heightCm = data?.formValues?.heightCm || this.store.values.heightCm
      this.formValues.weightKg = data?.formValues?.weightKg || this.store.values.weightKg
    } else {
      this.formValues.heightFt = data?.formValues?.heightFt || this.store.values.heightFt
      this.formValues.heightIn = data?.formValues?.heightIn || this.store.values.heightIn
      this.formValues.weightLbs = data?.formValues?.weightLbs || this.store.values.weightLbs
    }
  },

  getConvertedWeights() {
    if (!this.store.isMetric) {
      const { weightKg } = this.formValues

      const weightLbs = Math.round(kgToLbs(weightKg || 0))

      return weightLbs
    } else {
      const { weightLbs } = this.formValues

      const weightKg = Math.round(lbsToKg(weightLbs || 0))

      return weightKg
    }
  },

  getConvertedHeights() {
    if (!this.store.isMetric) {
      const { heightCm } = this.formValues

      const imperialHeight = cmToFtIn(heightCm || 0)
      const heightFt = imperialHeight.heightFt
      const heightIn = imperialHeight.heightIn

      return { heightFt, heightIn }
    } else {
      const { heightFt, heightIn } = this.formValues

      const heightCm = ftInToCm({ ft: heightFt || 0, inch: heightIn || 0 })

      return heightCm
    }
  },

  convertHeight() {
    if (!this.store.isMetric) {
      const { heightFt, heightIn } = this.getConvertedHeights()
      const weightLbs = this.getConvertedWeights()

      this.formValues.heightFt = heightFt
      this.formValues.heightIn = heightIn
      this.formValues.weightLbs = weightLbs
    } else {
      const heightCm = this.getConvertedHeights()
      const weightKg = this.getConvertedWeights()

      this.formValues.heightCm = heightCm
      this.formValues.weightKg = weightKg
    }
  },

  convertWeight() {
    if (!this.store.isMetric) {
      const weightLbs = this.getConvertedWeights()
      const { heightFt, heightIn } = this.getConvertedHeights()

      this.formValues.weightLbs = weightLbs
      this.formValues.heightFt = heightFt
      this.formValues.heightIn = heightIn
    } else {
      const weightKg = this.getConvertedWeights()
      const heightCm = this.getConvertedHeights()

      this.formValues.weightKg = weightKg
      this.formValues.heightCm = heightCm
    }
  },

  processFormValues() {
    const { age, firstName, heightCm, heightFt, heightIn, weightKg, weightLbs } = this.formValues

    if (this.store.isMetric) {
      return {
        heightCm,
        weightKg,
        age,
        firstName,
        isMetric: this.store.isMetric,
      }
    } else {
      return {
        age,
        firstName,
        heightFt,
        heightIn,
        weightLbs,
        isMetric: this.store.isMetric,
      }
    }
  },
})
