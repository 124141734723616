import "./polyfills"
import Bugsnag from "@bugsnag/js"
import dataStore from "./dataStore"
import translation from "./translation"
import views from "./views"

import Alpine from "alpinejs"

Bugsnag.start({ apiKey: "a383d74fed09040a8180a6388ef025f7", appVersion: appVersion })

window.Alpine = Alpine

views.forEach(({ name, data }) => {
  Alpine.data(name, data)
})

async function fetchDataFromApi() {
  const hashParams = new URLSearchParams(window.location.hash.substr(1))
  const token = hashParams.get("token")

  if (token) {
    dataStore.token = token
    await dataStore.pull()
  }
}

fetchDataFromApi().then(async () => {
  Alpine.store("data", dataStore)
  Alpine.start()
  translation.onInit()
})
