import { cmToFtIn, ftInToCm } from "../dataStore"

export default (getView) => (nextStepPath) => ({
  ...getView(nextStepPath),

  onInit(data) {
    if (this.store.isMetric) {
      this.formValues.heightCm = data?.formValues?.heightCm || this.store.values.heightCm
    } else {
      this.formValues.heightFt = data?.formValues?.heightFt || this.store.values.heightFt
      this.formValues.heightIn = data?.formValues?.heightIn || this.store.values.heightIn
    }
  },

  getConvertedHeights() {
    if (!this.store.isMetric) {
      const { heightCm } = this.formValues

      const imperialHeight = cmToFtIn(heightCm || 0)
      const heightFt = imperialHeight.heightFt
      const heightIn = imperialHeight.heightIn

      return { heightFt, heightIn }
    } else {
      const { heightFt, heightIn } = this.formValues

      const heightCm = ftInToCm({ ft: heightFt || 0, inch: heightIn || 0 })

      return heightCm
    }
  },

  convertHeight() {
    if (!this.store.isMetric) {
      const imperialHeights = this.getConvertedHeights()

      if (!imperialHeights) return

      this.formValues.heightFt = imperialHeights?.heightFt
      this.formValues.heightIn = imperialHeights?.heightIn
    } else {
      const heightCm = this.getConvertedHeights()

      this.formValues.heightCm = heightCm
    }
  },

  processFormValues() {
    const { heightCm, heightFt, heightIn } = this.formValues

    if (this.store.isMetric) {
      const imperialHeight = cmToFtIn(heightCm)
      const convertedHeightFt = imperialHeight.heightFt
      const convertedHeightIn = imperialHeight.heightIn

      return {
        heightFt: convertedHeightFt,
        heightIn: convertedHeightIn,
        heightCm,
        isMetric: this.store.isMetric,
      }
    } else {
      const convertedHeightCm = ftInToCm({ ft: heightFt, inch: heightIn })

      return { heightCm: convertedHeightCm, heightFt, heightIn, isMetric: this.store.isMetric }
    }
  },
})
