const store = (key, values) => {
  key = `${pipelineConfig.pathPrefix}:${key}`

  window.localStorage.setItem(key, JSON.stringify(values))
}

const getStoreByKeyValue = (key, valueKey) => {
  key = `${pipelineConfig.pathPrefix}:${key}`
  const data = window.localStorage.getItem(key) ?? null
  let store = null

  if (data) {
    store = JSON.parse(data)
  }
  if (store && store.values && valueKey && store.values[valueKey]) {
    return store.values[valueKey]
  }
  return store ?? null
}

const load = (key) => {
  key = `${pipelineConfig.pathPrefix}:${key}`

  const data = window.localStorage.getItem(key)

  if (data) {
    return JSON.parse(data)
  } else {
    return null
  }
}

export { store, load, getStoreByKeyValue }
