let $, _

export default {
  async onInit() {
    const hashParams = new URLSearchParams(window.location.hash.substr(1))
    const translationToken = hashParams.get("translation_token")
    const translationUrl = hashParams.get("translation_url")

    this.values = {
      token: translationToken,
      translationUrl: translationUrl,
      translation: null,
    }

    if (translationToken && translationUrl) {
      await import("https://cdn.skypack.dev/jquery@3.6.1").then((module) => ($ = module.default))
      await import("https://cdn.skypack.dev/lodash@4.17.21").then((module) => (_ = module.default))
      await this.pull()
      this.enableEdit()
    }
  },

  async pull() {
    const apiUrl = this.values.translationUrl

    await fetch(apiUrl, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        "x-token": this.values.token,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.error) {
          if ($) {
            $(".error").html(res.error)
          }
        } else {
          this.values.translation = res.data
        }
      })
  },

  enableEdit() {
    const values = this.values
    const push = this.push

    $("body").prepend(`
        <div class="w-full h-16 bg-white flex justify-between content-center p-2 sticky top-0 z-10" x-data="{ active: false }">
          <button class="rounded-full px-4 py-2 text-white" :class="active ? 'bg-green-800' : 'bg-green-500'" x-on:click="active = true; setTimeout(() => { active = false }, 1000)">Save Changes</button>
          <div class="error text-red-700"></div>
          <div class="message text-green-600"></div>
        </div>`)

    $("[x-i18n]")
      .prop({ tabindex: 1, contenteditable: true })
      .each(function () {
        const key = $(this).attr("x-i18n")
        const value = _.get(values.translation, key)
        $(this).html(value)
      })
      .on({
        focusin() {
          this.__html = $(this).html() // Store current HTML content
        },

        focusout() {
          const data = {
            key: $(this).attr("x-i18n"),
            html: this.innerHTML,
          }

          if (this.__html === data.html) return // Nothing has changed.

          _.set(values.translation, data.key, data.html)

          push(values)
        },
      })

    $(document).on("paste", function (e) {
      e.preventDefault()

      const clean = e.originalEvent.clipboardData.getData("Text").replace(/<(.|\n)*?>/g, "")

      const selection = window.getSelection()

      if (!selection.rangeCount) return false
      selection.deleteFromDocument()
      selection.getRangeAt(0).insertNode(document.createTextNode(clean))
    })
  },

  async push(values) {
    const apiUrl = values.translationUrl
    const options = {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
        "x-token": values.token,
      },
      body: JSON.stringify({
        ...values.translation,
      }),
    }

    const res = await fetch(apiUrl, options)
    const response = await res.json()

    if (response.error) {
      $(".message").text("")
      $(".error").text(response.error)
    } else {
      values.translation = response.data
      $(".message").text("Changes saved")
    }
  },
}
