import lottie from "lottie-web"
import Bugsnag from "@bugsnag/js"

import { load, store } from "./localStorage"
import renewedThankYou from "./../animations/congrats-renewed.json"
import exitIntentGift from "./../animations/exit-intent-gift.json"

const KGS_IN_LBS = 0.453592
const LBS_IN_KG = 2.20462
const INCH_IN_CM = 2.54
const CM_IN_FT = 30.48
const LOCAL_STORAGE_KEY = "data"
const INVALID_DOMAIN_CODE = "invalid_domain"
const EMAIL_VALIDATION_ERROR = "email: Invalid email"
const EMAIL_VALIDATION_CODE = "email_verification_failed"

const round = Math.round
export const kgToLbs = (kg) => kg / KGS_IN_LBS
export const lbsToKg = (lbs) => lbs / LBS_IN_KG
export const cmToFtIn = (cm) => {
  const totalInch = cm / INCH_IN_CM
  const feet = Math.floor(totalInch / 12)
  const inch = totalInch - 12 * feet

  return { heightFt: Math.round(feet), heightIn: Math.round(inch) }
}

export const ftInToCm = ({ ft, inch }) => {
  const heightCm = Math.round(ft * CM_IN_FT + inch * INCH_IN_CM)

  return heightCm
}

function initialState() {
  return {
    // Globals
    token: null,
    measurementSystem: pipelineConfig.measurementSystem,
    domain: null,
    goal: "Lose weight",
    // Cookies
    cookieAgreed: null,
    requiredCookies: true,
    analyticalCookies: true,
    marketingCookies: true,
    cookiePolicyBannerAccepted: false,

    // Step 0
    landingProceeded: null,
    mainGoal: null,

    // Step 01-hunger
    hunger: null,
    // Step 02-energy
    energy: null,
    // Step 03-weight
    weightKg: null,
    weightLbs: null,
    // Step 04-age
    age: null,
    // Step 05-height
    heightCm: null,
    heightFt: null,
    heightIn: null,
    // Step 06-gender
    gender: null,
    // Step 07-name
    firstName: null,

    // Step 10-ideal weight
    targetWeightKg: null,
    targetWeightLbs: null,

    // Step 13-plan-apetite
    apetite: null,
    ratherEat: null,
    smoking: null,

    // Step 14-plan-like
    likeBetter: null,
    beverageLikeBetter: null,
    feelBloated: null,

    // Step 15-plan-first-meal
    firstMealAfter: null,
    hormonalContraceptions: null,
    bonePain: null,
    fallingAsleep: null,

    // Step 16-plan-frequency
    eatingMeatOften: null,
    likeBetter2: null,
    hiccups: null,
    childrenUpTo18: null,
    alcoholConsumption: null,

    // Step 17-create-plan
    marketingAgreed: null,
    milkAllergy: null,
    privacyAgreement: null,
    weightLossReceive: null,
    email: null,

    //Step 19-checkout
    paymentPrice: null,
    showDiscountModal: false,

    isAbandonedBasketOrigin: false,
    exitIntent: false,
    paymentMethod: null,
    paymentSuccessUrl: null,
    paymentFailUrl: null,
    paymentUrl: null, // comes from backend when paymentMethod is set
    errorResponseData: null,
  }
}

export default {
  // Push data store to backend API
  init() {
    const data = load(LOCAL_STORAGE_KEY)

    if (data && data.values) {
      this.values = data.values
    } else {
      this.values = initialState()
    }

    this.storeAnalytics()

    Alpine.effect(() => {
      store(LOCAL_STORAGE_KEY, { values: this.values })
    })
  },

  storeAnalytics() {
    const analyticsData = {}

    analyticsData.domain = document.location.host

    // Add cookies - _ga, _gid, _fbc, _fbp, ...
    document.cookie.split("; ").forEach((item) => {
      const [key, value] = item.split("=")
      analyticsData[key] = value
    })

    // Add search params - fbclid, utm_*, ...
    const url = new URL(document.location)
    url.searchParams.forEach((value, key) => {
      analyticsData[key] = value
    })

    this.values = { ...this.values, ...analyticsData }
  },

  writeToLocalStorage(data) {
    window.localStorage.setItem(
      `${pipelineConfig.pathPrefix}:${LOCAL_STORAGE_KEY}`,
      JSON.stringify({ values: data })
    )
  },

  // Support boolean values
  convertValuesToBooleans(values) {
    const converted = {}

    Object.keys(values).forEach((key) => {
      let convertedValue

      switch (values[key]) {
        case "true":
          convertedValue = true
          break

        case "false":
          convertedValue = false
          break

        default:
          convertedValue = values[key]
      }

      converted[key] = convertedValue
    })

    return converted
  },

  convertValuesFromBooleans(values) {
    const dataValuesToConvert = ["marketingAgreed"]
    const converted = {}

    Object.keys(values).forEach((key) => {
      let convertedValue

      if (dataValuesToConvert.includes(key)) {
        switch (values[key]) {
          case true:
            convertedValue = "true"
            break

          case false:
            convertedValue = "false"
            break

          default:
            convertedValue = values[key]
        }
      }

      converted[key] = convertedValue || values[key]
    })

    return converted
  },

  async pull() {
    if (this.token) {
      const res = await fetch(pipelineConfig.orderApiUrl, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          "x-token": this.token,
        },
      })
      const data = await res.json()

      const convertedData = this.convertValuesFromBooleans(data)

      Object.entries(convertedData).forEach(([key, value]) => {
        this[key] = value
      })

      this.writeToLocalStorage({
        ...data,
        showDiscountModal: false,
        exitIntent: true,
        isAbandonedBasketOrigin: true,
      })
    }
  },

  // Push data store to backend API
  async push() {
    this.values.errorResponseData = null // Init error to null;
    const apiUrl = pipelineConfig.orderApiUrl
    const convertedValues = this.convertValuesToBooleans(this.values)
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(convertedValues),
    }

    if (this.values.token) {
      options.method = "PATCH"
      options.headers["x-token"] = this.values.token
    }

    try {
      const res = await fetch(apiUrl, options)
      const data = await res.json()

      if (res.ok) {
        this.values.errorResponseData = null
        this.error = undefined

        const convertedData = this.convertValuesFromBooleans(data)

        Object.entries(convertedData).forEach(([key, value]) => {
          this.values[key] = value
        })
      } else {
        if (
          data.code === INVALID_DOMAIN_CODE ||
          data.error === EMAIL_VALIDATION_ERROR ||
          data.code === EMAIL_VALIDATION_CODE
        ) {
          this.values.errorResponseData = data
          this.error = data.error
        } else {
          this.error = data.error
        }
      }
    } catch (error) {
      this.error = error
      Bugsnag.notify(error)
    }

    return !this.error
  },

  redirect(page) {
    if (!page) return

    window.location.pathname = page
  },

  agreeToCookies() {
    this.values.cookieAgreed = true
  },

  toggleAnalyticalCookies() {
    this.values.analyticalCookies = !this.values.analyticalCookies
  },

  toggleMarketingCookies() {
    this.values.marketingCookies = !this.values.marketingCookies
  },

  landingProceed() {
    this.values.landingProceeded = true
  },

  setMainGoal(goal, href) {
    this.values.mainGoal = goal

    this.redirect(href)
  },

  setMeasurementSystem(value) {
    if (!value) return

    this.values.measurementSystem = value
  },

  setGender(value) {
    this.values.gender = value
  },

  getProgressWidth(activeStepNumber) {
    if (activeStepNumber < 12) {
      return (activeStepNumber * 2.7).toFixed(2)
    }

    if (activeStepNumber < 19) {
      return (activeStepNumber * 3.53).toFixed(2)
    }

    if (activeStepNumber > 18 && activeStepNumber < 22) {
      return "85"
    }

    if (activeStepNumber == 22) {
      return "100"
    }
  },

  get normalizedHeight() {
    if (this.isMetric) {
      return this.values.heightCm
    } else {
      return this.values.heightFt * 30.48 + this.values.heightIn * 2.54
    }
  },

  get normalizedWeight() {
    if (this.isMetric) {
      return this.values.weightKg
    } else {
      return this.values.weightLbs * KGS_IN_LBS
    }
  },

  get isFemale() {
    return this.values.gender === "female"
  },

  get isMale() {
    return this.values.gender === "male"
  },

  get weightUnits() {
    return this.isMetric ? "kg" : "lbs"
  },

  get heightUnits() {
    return this.isMetric ? "cm" : "ft"
  },

  get isMetric() {
    return this.values.measurementSystem === "metric"
  },

  get isImperial() {
    return this.values.measurementSystem === "imperial"
  },

  get loseWeight() {
    return this.values.goal === "Lose weight"
  },

  get bmi() {
    return (this.normalizedWeight / (this.normalizedHeight / 100.0) ** 2).toFixed(0)
  },

  get overWeight() {
    return this.normalizedWeight - this.idealWeight
  },

  get idealWeight() {
    let value = round((this.normalizedHeight ** 2 * 21.1) / 10000)
    if (value < 30) {
      value = 30
    }
    return value
  },

  get idealWeightRange() {
    const minBMI = 18
    const maxBMI = 25
    const lowestWeightValue = this.isImperial ? round(kgToLbs(30)) : 30
    const normalizedHeight = this.normalizedHeight / 100.0

    let min = minBMI * Math.pow(normalizedHeight, 2)
    let max = maxBMI * Math.pow(normalizedHeight, 2)

    if (min >= max) {
      min = max - 1
    }

    if (this.isImperial) {
      min = kgToLbs(min)
      max = kgToLbs(max)
    }

    min = Math.max(round(min), lowestWeightValue)
    max = Math.max(round(max), lowestWeightValue)

    const idealRangeArray = []

    for (let i = min; i <= max; i++) {
      idealRangeArray.push(i)
    }

    return idealRangeArray
  },

  get idealWeightRangeOptions() {
    const idealRange = this.idealWeightRange
    const options = idealRange.map((val) => ({
      title: `${val}`,
      value: `${val}`,
    }))

    return options
  },

  get enabledDiscount() {
    return this.values.exitIntent
  },

  get showDiscountModal() {
    return this.values.showDiscountModal
  },

  formattedHeight() {
    const { heightCm, heightFt, heightIn } = this.values

    if (this.isImperial) {
      return pipelineConfig.dir === "rtl"
        ? `in${heightIn} ft${heightFt}`
        : `${heightFt}ft ${heightIn}in`
    } else {
      return pipelineConfig.dir === "rtl" ? `cm${heightCm}` : `${heightCm}cm`
    }
  },

  formatWeight(value) {
    return pipelineConfig.dir === "rtl"
      ? `${this.weightUnits}${value}`
      : `${value}${this.weightUnits}`
  },

  formattedWeight() {
    return this.formatWeight(this.isMetric ? this.values.weightKg : this.values.weightLbs)
  },

  formattedIdealWeight() {
    return this.formatWeight(this.isMetric ? this.idealWeight : round(kgToLbs(this.idealWeight)))
  },

  formattedMinIdealWeight() {
    return this.formatWeight(this.idealWeightRange[0])
  },

  formattedMaxIdealWeight() {
    return this.formatWeight(this.idealWeightRange.slice(-1)[0])
  },

  formattedWeightToLose() {
    const { weightKg, targetWeightKg, weightLbs, targetWeightLbs } = this.values

    return this.formatWeight(
      this.isMetric ? weightKg - targetWeightKg : weightLbs - targetWeightLbs
    )
  },

  formattedWeightGoal() {
    return this.formatWeight(
      this.isMetric ? this.values.targetWeightKg : this.values.targetWeightLbs
    )
  },

  async enableDiscount() {
    this.values.exitIntent = true
  },

  formattedPaymentMethod() {
    const paymentMethod = this.values.paymentMethod

    return paymentMethod
  },

  setShowDiscountModal(value) {
    this.values.showDiscountModal = value
  },

  handleInternalRedirect(activeStepNumber, exitIntentHandlerPath) {
    if (activeStepNumber == 19 && !this.enabledDiscount) {
      this.redirect(exitIntentHandlerPath)
    } else {
      history.back()
    }
  },

  handleCheckoutRedirect(nextStepPath) {
    this.setShowDiscountModal(!this.enabledDiscount)

    this.redirect(nextStepPath)
  },

  async trackVisitCheckout() {
    this.values.status = "VISIT_CHECKOUT"
    await this.push()
  },

  async pay(options) {
    this.values.paymentMethod = options.paymentMethod
    this.values.paymentSuccessUrl = options.paymentSuccessUrl
    this.values.paymentFailUrl = options.paymentFailUrl
    this.values.paymentPrice = options.paymentPrice

    if (await this.push()) {
      window.location = this.values.paymentUrl
    }
  },

  goToLogin() {
    const loginUrl = this.values.loginUrl
    this.values = initialState()
    window.location = loginUrl || pipelineConfig.loginUrl
  },

  resetLocalStorage() {
    const initialValues = initialState()
    const valuesToOmit = ["cookiePolicyBannerAccepted"]
    const omittedValues = {}
    Object.keys(this.values).forEach((key) => {
      if (valuesToOmit.includes(key)) {
        omittedValues[key] = this.values[key]
      }
    })

    this.values = {
      ...initialValues,
      ...omittedValues,
    }
    this.storeAnalytics()
  },

  async loadAnimation(id) {
    let animationData
    switch (id) {
      case "exit-intent-giftbox":
        animationData = exitIntentGift
        break
      default:
        animationData = renewedThankYou
    }
    lottie.loadAnimation({
      container: document.getElementById(id),
      animationData,
      renderer: "svg",
      loop: true,
      autoplay: true,
    })
  },

  isErrorBannerAllowed() {
    // Do not display for email step
    if (window.location.pathname.includes(pipelineConfig.pagePaths["17-create-plan"])) {
      return false
    }

    return true
  },
}
