export default () => ({
  cookiePolicyBannerAccepted: null,

  init() {
    this.store = Alpine.store("data")

    this.cookiePolicyBannerAccepted =
      window.localStorage.getItem("cookiePolicyBannerAccepted") === "true"
  },

  accept() {
    if (window.allConsentGranted) {
      // The `allConsentGranted` fn is bound to window
      // and controlled from the pipe-platform
      window.allConsentGranted()
    }

    this.cookiePolicyBannerAccepted = true
    window.localStorage.setItem("cookiePolicyBannerAccepted", "true")

    // Store if cookie consent granted with other data values for the user
    this.store.values = {
      ...this.store.values,
      cookiePolicyBannerAccepted: true,
    }
  },
})
